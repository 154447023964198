export enum AfriexRoles {
  USER = "user",
  ENGINEERING_MANAGER = "engineering.manager",
  ENGINEERING_MEMBER = "engineering.member",
  CUSTOMER_SUPPORT_MANAGER = "customer.support.manager",
  CUSTOMER_SUPPORT_MEMBER = "customer.support.member",
  COMPLIANCE_MEMBER = "compliance.member",
  COMPLIANCE_MANAGER = "compliance.manager",
  FINANCE_MANAGER = "finance.manager",
  FINANCE_MEMBER = "finance.member",
  GROWTH_MANAGER = "growth.manager",
  GROWTH_MEMBER = "growth.member",
  OPERATIONS_MANAGER = "operations.manager",
  OPERATIONS_MEMBER = "operations.member",
  PEOPLES_OPERATIONS_MANAGER = "peoples.operations.manager",
  PEOPLES_OPERATIONS_MEMBER = "peoples.operations.member",
  PRODUCT_ENG_QA_DESIGN_MEMBER = "product.eng.qa.design.member",
  PRODUCT_ENG_QA_DESIGN_MANAGER = "product.eng.qa.design.manager",
}

export enum AfriexOffboardingRoles {
  ENGINEERING_MANAGER = "engineering.manager",
  COMPLIANCE_MEMBER = "compliance.member",
  COMPLIANCE_MANAGER = "compliance.manager",
}

export enum AfriexPermissions {
  HOME_PAGE = "access-home-page",
  TRANSACTIONS_PAGE = "access-transactions-page",
  USERS_PAGE = "access-users-page",
  KYC_PAGE = "access-kyc-page",
  REFERRAL_PAGE = "access-referral-page",
  PUSH_NOTIFICATIONS_PAGE = "access-push-notifications-page",
  DISPUTES_PAGE = "access-disputes-page",
  RATES_PAGE = "access-rates-page",
  FEES_PAGE = "access-fees-page",
  BULK_PAYMENTS_PAGE = "access-bulk-payments-page",
  PROCESSORS_PAGE = "access-processors-page",
  BLOCKED_PAGE = "access-blocked-page",
  REWARDS_PAGE = "access-rewards-page",
  SETTINGS_PAGE = "access-settings-page",
}

export enum AfriexActions {
  // Transactions
  CLICK_TRANSACTION_SEARCH = "click-transaction-search",
  CLICK_TRANSACTION_EXPORT = "click-transaction-export",
  CLICK_VIEW_USER_TRANSACTIONS = "click-view-user-transactions",
  CLICK_REFUND_TRANSACTION = "click-refund-transaction",
  CLICK_DOWNLOAD_TRANSACTION_RECEIPT = "click-download-transaction-receipt",

  // Users
  CLICK_USER_SEARCH = "click-user-search",
  CLICK_USER_SUMMARY_VIEW_MORE = "click-user-summary-view-more",
  VIEW_USER_PHONE_NUMBER = "view-user-phone-number",
  VIEW_USER_STRIPE_DETAILS = "view-user-stripe-details",
  VIEW_USER_REWARD_POINTS = "view-user-reward-points",
  CLICK_UPDATE_USER = "click-update-user",
  CLICK_USER_VERIFY_UNVERIFY = "click-user-verify-unverify",
  CLICK_ADD_USER_SECURITY_NOTES = "click-add-user-security-notes",
  CLICK_USER_BLOCK_UNBLOCK = "click-user-block-unblock",
  CLICK_CREDIT_DEBIT_USER = "click-credit-debit-user",
  CLICK_SWAP_USER_BALANCE = "click-swap-user-balance",
  CLICK_USER_TIERS_UPDATE_DELETE = "click-user-tiers-update-delete",
  CLICK_VIEW_USER_KYC_DETAILS = "click-view-user-kyc-details",
  CLICK_DEACTIVATE_USER = "click-deactivate-user",
  VIEW_USER_INTERAC_EMAILS = "view-user-interac-emails",

  // Bulk Payments
  CLICK_BULK_PAYMENT_UPLOAD = "click-bulk-payment-upload-csv-file",
  VIEW_RECENT_BULK_PAYMENTS = "view-recent-bulk-payments",

  // Referrals
  CLICK_REFERRAL_SEARCH_FILTER = "click-referral-search-filter",
  CLICK_REFERRAL_PAYOUT = "click-referral-payout",
  CLICK_ADD_REMOVE_REFERRAL_TIER_USER = "click-add-remove-referral-tier-user",
  CLICK_CREATE_UPDATE_REFERRAL_TIER = "click-create-update-referral-tier",
  CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE = "click-create-update-signup-bonus-code",

  // KYC
  CLICK_KYC_SEARCH_FILTER = "click-kyc-search-filter",
  CLICK_KYC_REQUEST_DOCS = "click-kyc-request-documents",
  CLICK_DOWNLOAD_KYC_RECEIPT = "click-download-kyc-receipt",
  CLICK_KYC_UPDATE_INFO = "click-kyc-update-info",
  CLICK_KYC_RETRY_VERIFF = "click-kyc-retry-veriff",
  CLICK_KYC_DELETE_VERIFF_SESSION = "click-kyc-delete-veriff-session",

  // DISPUTES
  CLICK_UPDATE_DISPUTES = "click-update-disputes",

  // RATES
  CLICK_RATES_UPLOAD = "click-rates-upload",
  CLICK_RATES_UPLOAD_PRODUCTION = "click-rates-upload-production",
  CLICK_RATES_TOLERANCE_INPUT = "click-rates-tolerance-input",
  CLICK_RATES_OTC_UPLOAD = "click-rates-otc-upload",
  CLICK_RATES_APPROVE = "click-rates-approve",
  CLICK_RATES_CREATE_UPDATE_TIER = "click-rates-create-update-tier",

  // FEES
  CLICK_FEES_UPDATE = "click-fees-update",
  CLICK_FEES_ADD_OVERRIDE = "click-fees-add-override",
  CLICK_FEES_EDIT_DELETE = "click-fees-edit-delete",

  // PROCESSORS
  CLICK_PROCESSORS_UPDATE = "click-processors-update",
  CLICK_PROCESSORS_NOTIFY = "click-processors-notify",

  // BLOCKED
  CLICK_BLOCKED_ADD_PAYMENT_DESTINATION = "click-blocked-add-payment-destination",
  CLICK_BLOCKED_SEARCH_FILTER = "click-blocked-search-filter",
  CLICK_BLOCKED_UNBLOCK_BTN = "click-blocked-unblock-btn",

  // REWARDS
  CLICK_REWARDS_ADD_UPDATE = "click-rewards-add-update",
  CLICK_REWARDS_MANAGE_CARD = "click-rewards-manage",
  CLICK_REWARDS_UPLOAD_REMOVE = "click-rewards-upload-remove",

  // SETTINGS
  CLICK_SETTINGS_UPDATE_REFERRAL = "click-settings-update-referral",
  CLICK_SETTINGS_MANAGE_COUNTRY = "click-settings-manage-country",
  CLICK_SETTINGS_SEARCH_FILTER = "click-settings-search-filter",
  CLICK_SETTINGS_ADD_CURRENCY = "click-settings-add-currency",
  CLICK_SETTINGS_MANAGE_CURRENCY = "click-settings-manage-currency",
  CLICK_SETTINGS_DEACTIVATE_ADMIN = "click-settings-deactivate-admin",
  CLICK_SETTINGS_UPDATE_ROLE = "click-settings-update-role",
}
