import { useEffect, useRef, useState } from "react";
import { AfriexPerson } from "../../../types";

import Receipt from "../../../components/Receipt";
import { fileService, kycBucketKey } from "../../../services/fileService";
import Spinner from "../../../assets/svg/Spinner";
import Button from "../../../components/common/Button";
import { ExclamationCircleIcon, XIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";

interface UniversalImage {
  id: string;
  source: "kyc" | "veriff";
  key?: string; // e.g., 'front', 'back', 'selfie' for kycImages
  base64: string | null;
}

const KycReceipt = ({ data }: { data: AfriexPerson }) => {
  const kycImages = data.kyc?.images;
  const veriffImages = data.kyc?.media?.images;
  const [images, setImages] = useState<UniversalImage[]>([]);
  const [imagesLoading, setImagesLoading] = useState(true);
  const imageIdCounter = useRef(0);

  useEffect(() => {
    const fetchImages = async () => {
      if (!data?.id) return;

      setImagesLoading(true);

      try {
        const promises: Promise<UniversalImage | null>[] = [];
        // Process kycImages (front, back, selfie)
        if (kycImages) {
          Object.entries(kycImages).forEach(([key, value]) => {
            if (value) {
              const promise = fileService
                .s3ToBase64(String(value), kycBucketKey, data.id)
                .then((base64) => ({
                  id: `kyc-${imageIdCounter.current++}`,
                  source: "kyc" as const,
                  key,
                  base64,
                }))
                .catch((error) => {
                  console.error(`Error fetching KYC image (${key}):`, error);
                  return null;
                });
              promises.push(promise);
            }
          });
        }

        // Process veriffImages (array of URLs)
        if (veriffImages && veriffImages.length > 0) {
          veriffImages.forEach((url, index) => {
            if (url) {
              const promise = fileService
                .s3ToBase64(String(url), kycBucketKey, data.id)
                .then((base64) => ({
                  id: `veriff-${imageIdCounter.current++}`,
                  source: "veriff" as const,
                  key: `veriff-${index}`,
                  base64,
                }))
                .catch((error) => {
                  console.error(`Error fetching Veriff image (${url}):`, error);
                  return null;
                });
              promises.push(promise);
            }
          });
        }

        const results = await Promise.all(promises);

        // Filter out any null results due to fetch errors
        const validImages = results.filter(
          (img): img is UniversalImage => img !== null
        );

        setImages(validImages);
      } catch (error) {
        toast.error("Error fetching images");
      } finally {
        setImagesLoading(false);
      }
    };

    fetchImages();
  }, [data?.id, kycImages, veriffImages]);

  if (imagesLoading) {
    return <Spinner size={8} className="text-cyan-600" />;
  }

  if (images.length === 0) {
    return (
      <div className="main flex flex-col bg-receipt-bg p-5 mt-5 gap-4 text-cyan-600">
        No KYC documents
      </div>
    );
  }

  const removeImage = (id: string) => {
    setImages((prevImages) => prevImages.filter((img) => img.id !== id));
  };

  return (
    <div className="flex flex-col">
      <p className="flex font-sm gap-2 justify-center text-gray-600 bg-yellow-100 p-2 rounded-md">
        <ExclamationCircleIcon className="w-6 h-6 text-orange-500" />
        {"You can now remove images you don't want to print"}
      </p>

      <Receipt type="kyc">
        <div className="main flex flex-col bg-receipt-bg p-5 mt-5 gap-4">
          {images.map((image) => (
            <div key={image.id} className="relative">
              {image.base64 && (
                <img
                  src={image.base64}
                  alt={`${image.source} ${image.key}`}
                  className="w-full h-auto"
                />
              )}
              {/* Remove Button */}
              <div
                onClick={() => removeImage(image.id)}
                className="absolute top-2 right-2 p-0 font-sm no-print bg-red-500 rounded-full cursor-pointer"
              >
                <XIcon className="w-6 h-6 text-white" />
              </div>
            </div>
          ))}
        </div>
      </Receipt>
    </div>
  );
};

export default KycReceipt;
